import { parseISO } from 'date-fns';

import type { GetIncomeProfileR12Response } from 'api/queries/allocations/getIncomeProfileR12/types';
import unreachable from 'utils/unreachable';
import type { WithoutNulls } from 'utils/withoutNulls';

import {
  normalizeIncomeProfileAsset,
  normalizeIncomeProfileAssetAfterTax,
} from './IncomeProfileAsset';

type Input = NonNullable<GetIncomeProfileR12Response['data']>[number];

export const normalizeIncomeProfileMonth = (input: WithoutNulls<Input>) => {
  const id = input.monthDate ?? '';

  return {
    assets:
      input.monthlyIncomeDetail?.map((asset) =>
        normalizeIncomeProfileAsset(asset, id),
      ) ?? [],
    assetsAfterTax:
      input.monthlyIncomeDetailAfterTax?.map((asset) =>
        normalizeIncomeProfileAssetAfterTax(asset, id),
      ) ?? [],
    date: parseISO(input.monthDate ?? unreachable('1970-01-01')).getTime(),
    id,
    income: input.totalEstimatedIncome ?? 0,
    incomeAfterTax: input.totalEstimatedIncomeAfterTax ?? 0,
  } as const;
};

export type IncomeProfileMonth = ReturnType<typeof normalizeIncomeProfileMonth>;
