import { FC, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import { ContainerOverlay } from './styles';
import type { Props } from './types';

const CustomDate: FC<Props> = ({
  minTransactionDate,
  selectedDate,
  onChange,
  onClear,
  onFocus,
}) => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [startDate, endDate] = dateRange;
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  useEffect(() => {
    if (startDate && endDate) {
      onChange([startDate, endDate]);
    }

    if (!startDate && !endDate) {
      onClear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    setDateRange([
      selectedDate[0] ? selectedDate[0] : null,
      selectedDate[1] ? selectedDate[1] : null,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  useEffect(() => {
    if (isCalendarOpen) {
      onFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCalendarOpen]);

  return (
    <div>
      {isCalendarOpen && <ContainerOverlay />}
      <DatePicker
        wrapperClassName="react-datepicker-custom"
        selectsRange
        startDate={startDate}
        endDate={endDate}
        minDate={minTransactionDate}
        maxDate={new Date(Date.now())}
        onChange={(update) => {
          setDateRange(update);
        }}
        placeholderText="Select a date range"
        isClearable
        onCalendarOpen={() => {
          setIsCalendarOpen(true);
        }}
        onCalendarClose={() => {
          setIsCalendarOpen(false);
        }}
      />
    </div>
  );
};

export default CustomDate;
