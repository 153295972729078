import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h1`
  margin: 4rem 0 3.5rem;
`;

export const Portfolios = styled.ul`
  padding: 0;
  margin: 0;
`;
