import { useState } from 'react';
import { useRouteMatch } from 'react-router';

import useProfitLossChartData from 'api/hooks/charts/useProfitLossChartData';
import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import useMarketSummary from 'api/hooks/markets/useMarketSummary';

import type { Data, Period } from './AssetClassBreakdown/Controls/types';
import { usePerformanceBreakdownData, usePortfolioBalance } from './logic';

export default function useConnect() {
  const [controls, setControls] = useState<{
    data: Data;
    period: Period;
  }>({
    data: 'activity',
    period: 'MTD',
  });
  const [timeframe, setTimeframe] = useState<Date | undefined>(undefined);

  const { url } = useRouteMatch();

  const { selectedClient, selectedPortfolio } = useSelectedClient();
  const { leverage, portfolioBalance, portfolioEquity } = usePortfolioBalance(
    selectedPortfolio?.id,
    new Date(selectedPortfolio?.lastUpdated || new Date()),
  );
  const { data: performanceBreakdownReports } = usePerformanceBreakdownData({
    client: selectedClient?.id,
    portfolioName: selectedPortfolio?.name,
    url,
  });
  const { data: profitLossChartData } = useProfitLossChartData({
    client: selectedClient?.id,
    portfolioName: selectedPortfolio?.name,
  });

  const { data: marketSummaryData } = useMarketSummary({
    period: controls.period,
    timeframe,
    portfolio: selectedPortfolio?.id,
    lastUpdated: new Date(selectedPortfolio?.lastUpdated || new Date()),
  });

  return {
    leverage,
    performanceBreakdownReports,
    portfolioBalance,
    portfolioEquity,
    profitLossChartData,
    marketSummaryData,
    url,
    handle: {
      changeControls: setControls,
      changeTimeframe: setTimeframe,
    },
  };
}
