import { useMemo, useState } from 'react';

import type { IncomeProfileMonth } from 'model/IncomeProfileMonth';
import { formatMonthYear } from 'utils/dates';

type Option = {
  label: string;
  value: string;
};

export function useDateSelection({
  incomeProfileR12,
}: {
  incomeProfileR12: readonly IncomeProfileMonth[];
}) {
  const dateOptions = useMemo(
    (): readonly [Option, ...Option[]] => [
      {
        label: 'Summary',
        value: 'summary',
      },
      ...incomeProfileR12.map(({ date }) => ({
        value: `${date}`,
        label: `${formatMonthYear(date)}`,
      })),
    ],
    [incomeProfileR12],
  );

  const [selectedDate, setSelectedDate] = useState(dateOptions[0].value);

  const incomeProfileR12Detail = useMemo(
    () =>
      selectedDate === 'summary'
        ? undefined
        : incomeProfileR12.find((month) => `${month.date}` === selectedDate)
            ?.assets,
    [incomeProfileR12, selectedDate],
  );

  const incomeProfileR12DetailAfterTax = useMemo(
    () =>
      selectedDate === 'summary'
        ? undefined
        : incomeProfileR12.find((month) => `${month.date}` === selectedDate)
            ?.assetsAfterTax,
    [incomeProfileR12, selectedDate],
  );

  return {
    dateOptions,
    incomeProfileR12Detail,
    incomeProfileR12DetailAfterTax,
    selectedDate,
    setSelectedDate,
  };
}
