import { FC, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import FixedIncomeAssetDetailTable from 'components/FixedIncomeAssetDetailTable';
import { useAssetContext } from 'context/Asset';

import useConnect from './connect';
import { Container } from './styles';

const AssetDetailFixedIncome: FC = () => {
  const { setAsset } = useAssetContext();
  const {
    accruedInterest,
    asset,
    backUrl,
    couponsReceived,
    pnLExclCpn,
    aIatPurchase,
    duration,
    marketPrice,
    marketValue,
    cost,
    maturityDate,
    nominalAmount,
    parentPath,
    title,
    totalProfitLoss,
    url,
    yieldToWorst,
    tey,
    ttc,
    rating,
  } = useConnect();

  useEffect(() => {
    setAsset(asset?.id ?? '');
    setTimeout(() => {
      document.getElementById('header')?.scrollIntoView({ block: 'end' });
    }, 0);
  }, [asset?.id, setAsset]);

  if (!asset) {
    return <Redirect to={parentPath} />;
  }

  return (
    <Container parentUrl={backUrl} title="Fixed Income">
      <FixedIncomeAssetDetailTable
        accruedInterest={accruedInterest}
        couponsReceived={couponsReceived}
        pnLExclCpn={pnLExclCpn}
        aIatPurchase={aIatPurchase}
        duration={duration}
        marketPrice={marketPrice}
        marketValue={marketValue}
        cost={cost}
        maturityDate={maturityDate}
        nominalAmount={nominalAmount}
        parentUrl={backUrl}
        title={title}
        totalProfitLoss={totalProfitLoss}
        url={url}
        yieldToWorst={yieldToWorst}
        tey={tey}
        ttc={ttc}
        rating={rating}
      />
    </Container>
  );
};
export default AssetDetailFixedIncome;
