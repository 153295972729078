import styled from 'styled-components';

import DefaultIncomeProfileR12Chart from 'components/IncomeProfileR12Chart';
import Select from 'components/Select';

export const Container = styled.div``;

export const IncomeProfileR12Chart = styled(DefaultIncomeProfileR12Chart)`
  margin-bottom: 2.125rem;
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.4375rem;
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 1rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const ActionZone = styled.div`
  display: flex;
  align-items: center;
`;

export const SortButton = styled(Select)`
  width: fit-content;
  margin-left: 0.75rem;
`;

export const DataDisplayButton = styled(Select)`
  --min-height: 2.4375rem;
  margin-left: 1rem;
`;
