import { FC, memo, useMemo } from 'react';

import from from 'styles/responsive';
import useMedia from 'utils/useMedia';

import DesktopVersion from './DesktopVersion';
import MobileVersion from './MobileVersion';
import type { Props } from './types';

const endBalanceTitle = (period: Props['selectedPeriod']) => {
  switch (period) {
    case 'MTD':
      return 'Month to Date';
    case 'YTD':
      return 'Year to Date';
  }

  return '';
};

const AssetClassBreakdownTable: FC<Props> = ({
  data,
  selectedPeriod = 'MTD',
}) => {
  const headers = useMemo(
    () => [
      {
        id: 'name',
        label: 'Asset Class',
      },
      {
        id: 'activity',
        label: 'Activity',
      },
      {
        id: 'startBalance',
        label: `Beg. of ${selectedPeriod === 'MTD' ? 'Month' : 'Year'}`,
      },
      {
        id: 'startPortfolioPercentage',
        label: 'Port. %',
      },
      {
        id: 'changedAmount',
        label: 'P&L ($)',
      },
      {
        id: 'changedPercentage',
        label: 'P&L (%)',
      },
      {
        id: 'endBalance',
        label: endBalanceTitle(selectedPeriod),
      },
      {
        id: 'endPortfolioPercentage',
        label: 'Port. %',
      },
    ],
    [selectedPeriod],
  );

  const isDesktop = useMedia(from.laptop.query);
  const isTablet = useMedia(from.tablet.query);

  if (isDesktop || isTablet) {
    return <DesktopVersion data={data} headers={headers} />;
  }

  return <MobileVersion data={data.slice(0, -3)} />;
};

export default memo(AssetClassBreakdownTable);
