import { FC, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import useSelectedClient from 'api/hooks/clients/useSelectedClient';

import { ContainerOverlay } from './styles';
import type { Props } from './types';

const CustomDate: FC<Props> = ({ selectedPeriod, onChange, onClear }) => {
  const [date, setDate] = useState<Date | null>(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { selectedPortfolio } = useSelectedClient();

  useEffect(() => {
    if (date) {
      onChange(date);
    }

    if (!date) {
      onClear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    setDate(null);
  }, [selectedPeriod]);

  if (selectedPeriod === 'MTD') {
    return (
      <div>
        {isCalendarOpen && <ContainerOverlay />}
        <DatePicker
          wrapperClassName="react-datepicker-custom small"
          showMonthYearPicker
          selected={date}
          onChange={(update) => {
            setDate(update);
          }}
          placeholderText="Select Month"
          dateFormat="MMM/yyyy"
          minDate={new Date(selectedPortfolio?.inceptionDate ?? 0)}
          maxDate={new Date(selectedPortfolio?.lastUpdated ?? new Date())}
          isClearable
          onCalendarOpen={() => {
            setIsCalendarOpen(true);
          }}
          onCalendarClose={() => {
            setIsCalendarOpen(false);
          }}
        />
      </div>
    );
  }

  if (selectedPeriod === 'YTD') {
    return (
      <div>
        {isCalendarOpen && <ContainerOverlay />}
        <DatePicker
          wrapperClassName="react-datepicker-custom small"
          showYearPicker
          selected={date}
          onChange={(update) => {
            setDate(update);
          }}
          placeholderText="Select Year"
          dateFormat="yyyy"
          minDate={new Date(selectedPortfolio?.inceptionDate ?? 0)}
          maxDate={new Date(selectedPortfolio?.lastUpdated ?? new Date())}
          yearItemNumber={8}
          isClearable
          onCalendarOpen={() => {
            setIsCalendarOpen(true);
          }}
          onCalendarClose={() => {
            setIsCalendarOpen(false);
          }}
        />
      </div>
    );
  }

  return <></>;
};

export default CustomDate;
