import useGetMarketSummaryQuery, {
  GET_MARKET_SUMMARY_QUERY_KEY,
} from 'api/queries/markets/getMarketSummary';
import { useQuery } from 'api/storage';

export default function useMarketSummary({
  period,
  timeframe,
  portfolio,
  lastUpdated,
}: {
  period: 'MTD' | 'YTD';
  timeframe: Date | undefined;
  portfolio: string | undefined;
  lastUpdated: Date;
}) {
  let timeframeParse = '';
  const auxTimeframe = timeframe || new Date(lastUpdated);
  if (period === 'MTD') {
    auxTimeframe.setMonth(auxTimeframe.getMonth() + 1, 0);
    timeframeParse = `${(auxTimeframe.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${auxTimeframe
      .getDate()
      .toString()
      .padStart(2, '0')}-${auxTimeframe.getFullYear().toString()}`;
  } else if (period === 'YTD') {
    timeframeParse = auxTimeframe.getFullYear().toString();
  }

  const { data } = useQuery(
    GET_MARKET_SUMMARY_QUERY_KEY({
      timeframe: timeframeParse,
      portfolio: portfolio ?? '',
    }),
    {
      enabled: !!portfolio,
      queryFn: useGetMarketSummaryQuery(),
      keepPreviousData: true,
    },
  );

  return {
    data,
  };
}
