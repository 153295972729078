import { useState } from 'react';

import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import useAssetClassBreakdown from 'api/hooks/home/useAssetClassBreakdown';

import type { Data, Period } from './Controls/types';

export default function useConnect() {
  const [controls, setControls] = useState<{
    data: Data;
    period: Period;
  }>({
    data: 'activity',
    period: 'MTD',
  });

  const [timeframe, setTimeframe] = useState<Date | undefined>(undefined);

  const { selectedPortfolio } = useSelectedClient();
  const { data: assetClassBreakdown } = useAssetClassBreakdown({
    period: controls.period,
    timeframe,
    portfolio: selectedPortfolio?.id,
    lastUpdated: new Date(selectedPortfolio?.lastUpdated || new Date()),
  });

  return {
    assetClassBreakdown,
    controls,
    handle: {
      changeControls: setControls,
      changeTimeframe: setTimeframe,
    },
  };
}
