import type { GetIncomeProfileR12Response } from 'api/queries/allocations/getIncomeProfileR12/types';
import kebabCaseCustom from 'utils/kebabCaseCustom';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<
  NonNullable<
    GetIncomeProfileR12Response['data']
  >[number]['monthlyIncomeDetail']
>[number];

export const normalizeIncomeProfileAsset = (
  input: WithoutNulls<Input>,
  parentId: string,
) =>
  ({
    id: `${parentId}_${kebabCaseCustom(input.assetName ?? '')}`,
    // In back this is typed as nullable string, but it's a nullable number
    income: (input.income as unknown as number | undefined | null) ?? 0,
    name: input.assetName ?? '',
  } as const);

export type IncomeProfileAsset = ReturnType<typeof normalizeIncomeProfileAsset>;

type InputAfterTax = NonNullable<
  NonNullable<
    GetIncomeProfileR12Response['data']
  >[number]['monthlyIncomeDetailAfterTax']
>[number];

export const normalizeIncomeProfileAssetAfterTax = (
  input: WithoutNulls<InputAfterTax>,
  parentId: string,
) =>
  ({
    id: `${parentId}_${kebabCaseCustom(input.assetName ?? '')}`,
    // In back this is typed as nullable string, but it's a nullable number
    income: (input.incomeAfterTax as unknown as number | undefined | null) ?? 0,
    name: input.assetName ?? '',
  } as const);

export type IncomeProfileAssetAfterTax = ReturnType<
  typeof normalizeIncomeProfileAssetAfterTax
>;
