import type { FC } from 'react';
import { createContext, useCallback, useContext, useMemo } from 'react';

import type { Props } from './types';

const Context = createContext({
  onOpenPortfolioSwitcher: () => {},
});

export function useOpenPortfolioSwitcher() {
  const { onOpenPortfolioSwitcher } = useContext(Context);
  return onOpenPortfolioSwitcher;
}

const PortfolioSwitcherContext: FC<Props> = ({ children, menuRef }) => {
  const onOpenPortfolioSwitcher = useCallback(() => {
    void menuRef.current?.open();
  }, [menuRef]);

  const value = useMemo(
    () => ({ onOpenPortfolioSwitcher }),
    [onOpenPortfolioSwitcher],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default PortfolioSwitcherContext;
