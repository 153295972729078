import { FC, memo } from 'react';

import Amount from 'components/Amount';
import from from 'styles/responsive';
import useMedia from 'utils/useMedia';

import {
  AmountCol,
  ChangePercentageCol,
  Content,
  FirstCol,
  Header,
  HeaderCell,
  HeaderTitle,
  Row,
  Table,
  VectorDownIcon,
  VectorUpIcon,
} from './styles';
import type { Props } from './types';

const ReportCategoryDetailTable: FC<Props> = ({
  assets,
  isSinceInception,
  handleSortByColumn,
  sortApplied,
  headerColumns,
}) => {
  const isTablet = useMedia(from.tablet.query);

  return (
    <Table>
      {isTablet && (
        <Header $isSinceInception={isSinceInception}>
          {headerColumns.map((col) => (
            <HeaderCell key={col.titleColumn}>
              <HeaderTitle
                onClick={() =>
                  handleSortByColumn ? handleSortByColumn(col) : undefined
                }
              >
                {col.titleColumn}
                {sortApplied?.titleColumn === col.titleColumn &&
                  (sortApplied?.orderOrientation === 'DESC' ? (
                    <VectorDownIcon />
                  ) : (
                    <VectorUpIcon />
                  ))}
              </HeaderTitle>
            </HeaderCell>
          ))}
        </Header>
      )}
      <Content>
        {assets.map((asset) => (
          <Row key={asset.id} $isSinceInception={isSinceInception}>
            <FirstCol>
              {asset.name}
              <Amount value={asset.currentBalance} />
            </FirstCol>
            <AmountCol value={asset.profitAndLoss} />
            {!isSinceInception && (
              <ChangePercentageCol value={asset.percentChange} />
            )}
          </Row>
        ))}
      </Content>
    </Table>
  );
};

export default memo(ReportCategoryDetailTable);
