import type { FC } from 'react';

import MarketSummaryChart from 'components/MarketSummaryChart';
import from from 'styles/responsive';
import { formatPercentage } from 'utils/percentages';
import useMedia from 'utils/useMedia';

import { useScales, useValues } from '../logic';
import type { Props } from '../types';
import Legend from './Legend';
import { useTitlePaddingLeft, useTitleTextAlign } from './logic';
import {
  ChartContainer,
  ChartContainerHeader,
  ChartInfo,
  Column,
  Container,
  Header,
  TextSmall,
  TextSmallBold,
  Title,
} from './styles';

const MarketSummary: FC<Props> = ({ marketSummaryData }) => {
  const { values } = useValues(marketSummaryData, 'year');
  const { valueScale } = useScales(values);
  const isLaptop = useMedia(from.laptop.query);

  const dataYTD = marketSummaryData.map((dt) => dt.ytd);

  const textAlign = useTitleTextAlign(dataYTD);
  const paddingLeft = useTitlePaddingLeft(
    dataYTD,
    valueScale.ticks(),
    isLaptop,
  );

  if (!marketSummaryData.length) {
    return <></>;
  }

  let keys = marketSummaryData.map((dt) => dt.instrumentType);
  keys = keys.filter((v, i) => !keys.includes(v, i + 1));

  return (
    <Container>
      <Header>
        <Title>Market Summary</Title>
      </Header>
      {marketSummaryData && (
        <>
          <ChartContainerHeader>
            <span />
            <TextSmallBold>Month to Date</TextSmallBold>
            <TextSmallBold $align={textAlign} $paddingLeft={paddingLeft}>
              Year to Date
            </TextSmallBold>
          </ChartContainerHeader>
          <ChartContainer>
            <ChartInfo>
              <Column $rows={marketSummaryData.length} $isNameColumn>
                {marketSummaryData.map((dt) => (
                  <TextSmall key={dt.id}>{dt.name}</TextSmall>
                ))}
              </Column>
              <Column $rows={marketSummaryData.length}>
                {marketSummaryData.map((dt) => (
                  <TextSmall key={dt.id}>{formatPercentage(dt.mtd)}</TextSmall>
                ))}
              </Column>
              <div id="market-summary-chart">
                <MarketSummaryChart
                  data={[...marketSummaryData].reverse()}
                  height={318}
                  valueScale={valueScale}
                />
              </div>
            </ChartInfo>
            <Legend keys={keys} data={marketSummaryData} />
          </ChartContainer>
        </>
      )}
    </Container>
  );
};

export default MarketSummary;
