import { localPoint } from '@visx/event';
import { scaleBand, scaleLinear } from '@visx/scale';
import { useTooltip as useVisxTooltip } from '@visx/tooltip';
import type { MouseEvent, TouchEvent } from 'react';
import { useMemo, useState } from 'react';

import type { Theme } from 'styles/themes';
import unreachable from 'utils/unreachable';

import type { BarKey, ChartKey, Datum, TooltipDatum } from './types';

export const getDate = (d: Datum) => d.date;
export function formatChartKey(key: ChartKey) {
  switch (key) {
    default:
    case 'activity':
      return 'Activity';
    case 'initialBalance':
      return 'Initial Balance';
    case 'loan':
      return 'Loan';
    case 'profitAndLoss':
      return 'Profit & Loss';
    case 'balance':
      return 'Balance';
  }
}

export function getChartKeyColor(key: ChartKey, theme: Theme) {
  switch (key) {
    case 'activity':
      return theme.colors.dataBlue;
    case 'initialBalance':
      return theme.colors.primary50;
    case 'loan':
      return theme.colors.dataCarrot;
    case 'balance':
      return theme.colors.primary;
    default:
    case 'profitAndLoss':
      return theme.colors.dataMantis;
  }
}

function isChartKey(
  key: string | undefined,
  chartKeys: readonly ChartKey[],
): key is ChartKey {
  return chartKeys.includes(key as ChartKey);
}

export function useHeight({
  isTablet,
  isLandscape,
}: {
  isTablet: boolean;
  isLandscape: boolean;
}): number {
  if (isTablet) {
    return window.innerHeight - 280;
  }

  return isLandscape ? 320 : window.innerHeight - 330;
}

export function useEdgeValues({
  barKeys,
  data,
}: {
  barKeys: readonly BarKey[];
  data: readonly Datum[];
}) {
  const start = useMemo(
    () =>
      data.reduce(
        (lowestTime, datum) => Math.min(lowestTime, datum.date),
        Infinity,
      ),
    [data],
  );

  const end = useMemo(
    () =>
      data.reduce((highestTime, datum) => Math.max(highestTime, datum.date), 0),
    [data],
  );

  const edges = useMemo(
    () =>
      data.map((datum) => {
        const values = [...barKeys].map((key) => datum[key]);

        const positiveValues = values.filter((value) => value >= 0);
        const negativeValues = values.filter((value) => value < 0);

        const startValue = negativeValues.reduce(
          (acc, value) => acc + value,
          0,
        );

        const endValue = positiveValues.reduce((acc, value) => acc + value, 0);

        return { startValue, endValue: Math.max(datum.balance, endValue) };
      }),
    [barKeys, data],
  );

  const maxValue = useMemo(
    () => Math.max(...edges.map((edge) => edge.endValue)),
    [edges],
  );
  const minValue = useMemo(
    () => Math.min(...edges.map((edge) => edge.startValue)),
    [edges],
  );

  return {
    end,
    maxValue,
    minValue,
    start,
  };
}

export function useMargins({ isTablet }: { isTablet: boolean }) {
  // This builds on top of the padding of the Container element. Instead of
  // putting all on the padding, we put 1rem here so the chart line, which has
  // a thick stroke, doesn't get cut. We also put the rest on the Container
  // element so it is easier for other developers to change the styles.
  const topMargin = 16;

  // This can't simply be padding on the Container element because it wouldn't
  // be noticed by the resize observer.
  const rightMargin = 32;

  const bottomAxisHeight = 60;
  const bottomAxisMargin = 19;

  const leftAxisWidth = isTablet ? 70 : 40;
  const leftAxisMargin = 16;

  return {
    bottomAxisHeight,
    bottomAxisMargin,
    leftAxisMargin,
    leftAxisWidth,
    rightMargin,
    topMargin,
  };
}

export function useScales(props: {
  bottomAxisHeight: number;
  bottomAxisMargin: number;
  data: Datum[];
  end: number;
  height: number;
  leftAxisMargin: number;
  leftAxisWidth: number;
  maxValue: number;
  minValue: number;
  rightMargin: number;
  start: number;
  topMargin: number;
  width: number;
}) {
  // Destructured here rather than on the "props" parameter itself so that the
  // parameter hint in IntelliJ based IDEs isn't gigantic.
  const {
    bottomAxisHeight,
    bottomAxisMargin,
    data,
    height,
    leftAxisMargin,
    leftAxisWidth,
    maxValue,
    minValue,
    rightMargin,
    topMargin,
    width,
  } = props;

  const xScaleBars = useMemo(
    () =>
      scaleBand({
        domain: data.map(getDate),
        padding: 0.2,
        range: [leftAxisWidth + leftAxisMargin, width - rightMargin],
        paddingOuter: 0,
      }),
    [leftAxisMargin, leftAxisWidth, rightMargin, width, data],
  );

  const balanceXScale = useMemo(
    () =>
      scaleBand({
        domain: data.map(getDate),
        padding: 0.2,
        range: [leftAxisWidth + leftAxisMargin, width - rightMargin],
        paddingOuter: 0,
      }),
    [leftAxisMargin, leftAxisWidth, rightMargin, width, data],
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [minValue, maxValue],
        range: [height - bottomAxisHeight - bottomAxisMargin, topMargin],
        nice: true,
      }),
    [bottomAxisHeight, bottomAxisMargin, height, minValue, maxValue, topMargin],
  );

  return { xScaleBars, balanceXScale, yScale };
}

export function useTooltip({
  chartKeys,
  data,
}: {
  balanceXScale: ReturnType<typeof useScales>['balanceXScale'];
  yScale: ReturnType<typeof useScales>['yScale'];
  chartKeys: readonly ChartKey[];
  data: readonly Datum[];
  leftAxisMargin: number;
  leftAxisWidth: number;
  height: number;
  width: number;
}) {
  const { hideTooltip, showTooltip, tooltipData, tooltipLeft, tooltipTop } =
    useVisxTooltip<TooltipDatum>();

  const handleTooltipUpdated = useMemo(
    () => (event: MouseEvent<SVGRectElement> | TouchEvent<SVGRectElement>) => {
      if (event.target instanceof SVGRectElement) {
        // bar chart tooltip
        const { x: xBar, y: yBar } = localPoint(event) ?? { x: 0, y: 0 };

        const { datumId, key } = event.target.dataset;
        const datumBar = data.find((it) => it.id === datumId);

        if (!datumBar || !isChartKey(key, chartKeys)) {
          return unreachable(undefined);
        }
        showTooltip({
          tooltipData: {
            date: datumBar.date,
            key,
            value: datumBar[key],
          },
          tooltipLeft: xBar,
          tooltipTop: yBar,
        });
      }

      return undefined;
    },
    [chartKeys, data, showTooltip],
  );

  const handleTooltipBalanceUpdated = useMemo(
    () => (event: MouseEvent<SVGRectElement> | TouchEvent<SVGRectElement>) => {
      if (event.target instanceof SVGRectElement) {
        // path chart tooltip
        const { x: xBar, y: yBar } = localPoint(event) ?? { x: 0, y: 0 };

        const datumId = event.target.id;
        const datumBar = data.find((d) => `bar-path-${d.id}` === datumId);

        if (!datumBar) {
          return unreachable(undefined);
        }

        showTooltip({
          tooltipData: {
            date: datumBar?.date ?? 0,
            key: 'balance',
            value: datumBar?.balance ?? 0,
          },
          tooltipTop: yBar,
          tooltipLeft: xBar,
        });
      }

      return undefined;
    },
    [data, showTooltip],
  );

  return {
    handleTooltipClosed: hideTooltip,
    handleTooltipUpdated,
    handleTooltipBalanceUpdated,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  };
}

export function useBalanceArrow(data: readonly Datum[]) {
  const [showBalanceArrow, setShowBalanceArrow] = useState<
    number | undefined
  >();

  function handleArrowBalanceClosed() {
    return setShowBalanceArrow(undefined);
  }

  function handleArrowBalanceUpdated(
    event: MouseEvent<SVGRectElement> | TouchEvent<SVGRectElement>,
  ) {
    if (event.target instanceof SVGRectElement) {
      const datumId = event.target.id;
      const datumBar = data.find((d) => `bar-path-${d.id}` === datumId);
      setShowBalanceArrow(datumBar?.date);
    }

    return undefined;
  }

  return {
    showBalanceArrow,
    handleArrowBalanceClosed,
    handleArrowBalanceUpdated,
  };
}
