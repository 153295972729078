import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';
import { normalizeAssetClassBreakdownReport } from 'model/AssetClassBreakdownReport';
import type { AssetClassBreakdownReport } from 'model/AssetClassBreakdownReport';
import getPortfolioId from 'utils/getPorfolioId';
import withoutNulls from 'utils/withoutNulls';

import type {
  GetAssetClassBreakdownRequest,
  GetAssetClassBreakdownResponse,
} from './types';

export const GET_ASSET_CLASS_BREAKDOWN_QUERY_KEY = ({
  timeframe,
  portfolio,
}: {
  timeframe: string;
  portfolio: string;
}) => ['home/getAssetClassBreakdown', timeframe, portfolio];

export default function useGetAssetClassBreakdownQuery(): QueryFunction<
  AssetClassBreakdownReport[]
> {
  const request = useRequest<
    GetAssetClassBreakdownRequest,
    GetAssetClassBreakdownResponse
  >();

  return useCallback(
    async ({ queryKey }) => {
      const [, timeframe, portfolio] = queryKey as [string, string, string];

      const response = await request(
        // eslint-disable-next-line no-nested-ternary
        '/api/CVData/getAssetClassBreakDown_timeframe/{portfolio}/{timeframe}',
        {
          urlParams: {
            portfolio: getPortfolioId(portfolio) ?? '',
            timeframe,
          },
        },
      );

      const data = await response.json();

      if (!data.isSuccess) {
        throw new Error(data.message ?? '');
      }

      return withoutNulls(data.data ?? []).map(
        normalizeAssetClassBreakdownReport,
      );
    },
    [request],
  );
}
