import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';
import { MarketSummary, normalizeMarketSummary } from 'model/MarketSummary';
import getPortfolioId from 'utils/getPorfolioId';
import withoutNulls from 'utils/withoutNulls';

import type {
  GetMarketSummaryRequest,
  GetMarketSummaryResponse,
} from './types';

export const GET_MARKET_SUMMARY_QUERY_KEY = ({
  timeframe,
  portfolio,
}: {
  timeframe: string;
  portfolio: string;
}) => ['market/getMarketSummary', timeframe, portfolio];

export default function useGetMarketSummaryQuery(): QueryFunction<
  MarketSummary[]
> {
  const request = useRequest<
    GetMarketSummaryRequest,
    GetMarketSummaryResponse
  >();

  return useCallback(
    async ({ queryKey }) => {
      const [, timeframe, portfolio] = queryKey as [string, string, string];
      const response = await request(
        '/api/CVData/getMarketSummary/{portfolio}/{dateOrYear}',
        {
          urlParams: {
            portfolio: getPortfolioId(portfolio) ?? '',
            dateOrYear: timeframe,
          },
        },
      );
      const data = await response.json();

      if (!data.isSuccess) {
        throw new Error(data.message ?? '');
      }
      return withoutNulls(data.data ?? []).map(normalizeMarketSummary);
    },
    [request],
  );
}
