import styled, { css } from 'styled-components';

import { ReactComponent as DefaultVectorDownIcon } from 'assets/icons/vector-down.svg';
import { ReactComponent as DefaultVectorUpIcon } from 'assets/icons/vector-up.svg';
import DefaultAmount from 'components/Amount';
import from from 'styles/responsive';

export const FirstCol = styled.div`
  text-align: left;
  color: ${({ theme }) => theme.colors.white};
  line-height: 1.1875rem;
  span {
    display: block;
    margin-top: 0.25rem;
    line-height: 1.0625rem;
    color: ${({ theme }) => theme.colors.white75};
    font-size: 0.6875rem;
  }
`;

export const Table = styled.div`
  display: grid;
  color: ${({ theme }) => theme.colors.white};
  line-height: 1.1875rem;
  color: ${({ theme }) => theme.colors.white};
  > :nth-child(even) {
    background: ${({ theme }) => theme.colors.dark1};
  }
  font-size: 0.725rem;
  ${from.laptop`
    font-size: 0.825rem;
  `}
  ${from.desktop`
    font-size: 1rem;
  `}
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 21.5% repeat(6, 12.5%);
  column-gap: 0.5rem;
  padding: 1rem;
  color: ${({ theme }) => theme.colors.white75};
  line-height: 1.0625rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  text-align: center;
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.colors.dark2};
  > :first-child {
    text-align: left;
  }
`;

export const HeaderCell = styled.div``;

export const HeaderTitle = styled.span`
  cursor: pointer;
  display: inline-flex;
`;

export const Title = styled.span`
  width: max-content;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 21.5% repeat(6, 12.5%);
  column-gap: 0.5rem;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.white10};
  padding: 1rem;
`;

export const Col = styled.div`
  text-align: center;
  span {
    color: ${({ theme }) => theme.colors.white75};
    display: block;
    margin-top: 0.25rem;
    font-size: 0.6875rem;
    line-height: 1.0625rem;
  }
`;

const columnStyles = css`
  text-align: center;
`;

export const ColouredAmount = styled(DefaultAmount).attrs({ coloured: true })`
  ${columnStyles};
`;

export const VectorDownIcon = styled(DefaultVectorDownIcon)``;

export const VectorUpIcon = styled(DefaultVectorUpIcon)``;
