import { FC, memo } from 'react';

import { formatAmount } from 'utils/amounts';

import {
  Col,
  FirstCol,
  Header,
  HeaderCell,
  HeaderTitle,
  Row,
  Table,
  Title,
  VectorDownIcon,
  VectorUpIcon,
} from './styles';
import type { Props } from './types';

const CashTable: FC<Props> = ({
  data,
  handleSortByColumn,
  sortApplied,
  headerColumns,
}) => (
  <Table>
    <Header>
      {headerColumns.map((col) => (
        <HeaderCell key={col.titleColumn}>
          <HeaderTitle
            onClick={() =>
              handleSortByColumn ? handleSortByColumn(col) : undefined
            }
          >
            <Title>{col.titleColumn}</Title>
            {sortApplied?.titleColumn === col.titleColumn &&
              (sortApplied?.orderOrientation === 'DESC' ? (
                <VectorDownIcon />
              ) : (
                <VectorUpIcon />
              ))}
          </HeaderTitle>
        </HeaderCell>
      ))}
    </Header>
    {data.map((row) => (
      <Row key={row.ranking}>
        <FirstCol>
          {row.entity} {row.account}
        </FirstCol>
        <Col>{formatAmount(row.cashUSD)}</Col>
        <Col>{formatAmount(row.cashOther)}</Col>
        <Col>{formatAmount(row.cashEquivalent)}</Col>
        <Col>{formatAmount(row.treasuries)}</Col>
        <Col>{formatAmount(row.totalLiquidityAvailable)}</Col>
      </Row>
    ))}
  </Table>
);

export default memo(CashTable);
