import type { FC } from 'react';

import CoverageTeam from './CoverageTeam';
import Portfolio from './Portfolio';
import { Container, Portfolios, Title } from './styles';
import type { Props } from './types';

const ProfileSwitcherAccount: FC<Props> = ({
  name,
  portfolios,
  onChangeSelectedPortfolio,
  selectedPortfolioId,
}) => (
  <>
    <Container>
      <Title>{name}</Title>
      <Portfolios>
        {portfolios.map((portfolio) => (
          <Portfolio
            id={portfolio.id}
            isDefault={portfolio.isDefault}
            key={portfolio.id}
            name={portfolio.name}
            onChangeSelectedPortfolio={onChangeSelectedPortfolio}
            selectedPortfolioId={selectedPortfolioId}
          />
        ))}
      </Portfolios>
    </Container>
    <CoverageTeam />
  </>
);

export default ProfileSwitcherAccount;
