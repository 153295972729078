import useIncomeProfileR12 from 'api/hooks/allocations/useIncomeProfileR12';
import useSelectedClient from 'api/hooks/clients/useSelectedClient';

import { useDateSelection } from './logic';

export default function useConnect() {
  const { selectedPortfolio } = useSelectedClient();

  const { data: incomeProfileR12 = [] } = useIncomeProfileR12(
    selectedPortfolio?.id,
  );

  const {
    dateOptions,
    incomeProfileR12Detail,
    incomeProfileR12DetailAfterTax,
    selectedDate,
    setSelectedDate,
  } = useDateSelection({
    incomeProfileR12,
  });

  return {
    dateOptions,
    handle: { changeSelectedDate: setSelectedDate },
    incomeProfileR12,
    incomeProfileR12Detail,
    incomeProfileR12DetailAfterTax,
    selectedDate,
  };
}
