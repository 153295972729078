import type { FC, SetStateAction } from 'react';

import AssetClassBreakdownTable from 'components/AssetClassBreakdownTable';

import useConnect from './connect';
import Controls from './Controls';
import type { Data, Period } from './Controls/types';
import CustomDate from './CustomDate';
import {
  Container,
  ContainerControls,
  ContainerCustomDate,
  Header,
  Title,
} from './styles';
import type { Props } from './types';

const AssetClassBreakdown: FC<Props> = ({
  changeTimeframe,
  changeControls,
}) => {
  const { assetClassBreakdown, controls, handle } = useConnect();

  const handleOnChangeTimeframe = (value: Date) => {
    handle.changeTimeframe(value);
    changeTimeframe(value);
  };

  const handleOnClearTimeframe = () => {
    handle.changeTimeframe(undefined);
    changeTimeframe(undefined);
  };

  const handleOnChangeControls = (
    value: SetStateAction<{ data: Data; period: Period }>,
  ): void => {
    handle.changeControls(value);
    changeControls(value);
  };

  return (
    <Container>
      <Header>
        <Title>Asset Class Breakdown</Title>
        <ContainerControls>
          <ContainerCustomDate>
            <CustomDate
              selectedPeriod={controls.period}
              onChange={handleOnChangeTimeframe}
              onClear={handleOnClearTimeframe}
            />
          </ContainerCustomDate>
          <Controls
            onChange={handleOnChangeControls}
            selectedData={controls.data}
            selectedPeriod={controls.period}
          />
        </ContainerControls>
      </Header>
      {assetClassBreakdown && (
        <AssetClassBreakdownTable
          data={assetClassBreakdown}
          selectedData={controls.data}
          selectedPeriod={controls.period}
        />
      )}
    </Container>
  );
};

export default AssetClassBreakdown;
