import { useMemo } from 'react';
import { useLocation, useRouteMatch } from 'react-router';

import useAllocationAsset from 'api/hooks/allocations/useAllocationAsset';
import useAllocationGroups from 'api/hooks/allocations/useAllocationGroups';
import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import up from 'utils/paths';

import {
  useCurrentGrouping,
  useNavigationLinks,
} from '../CategoryDetail/logic';

export default function useConnect() {
  const {
    params: { assetSlug },
    url,
  } = useRouteMatch<{ assetSlug: string }>();
  const categorySlug = 'fixed-income';
  const { search } = useLocation();
  const parentPath = useMemo(() => up(url, 2), [url]);

  const grouping = useCurrentGrouping({ search });

  const { selectedPortfolio } = useSelectedClient();

  const { getNameBySlug } = useAllocationGroups({
    groupBy: 'asset-breakdown',
    subselection: undefined,
    portfolio: selectedPortfolio?.id,
  });

  const { backUrl } = useNavigationLinks({
    categorySlug,
    getNameBySlug,
    grouping,
    search,
    url,
  });

  const asset = useAllocationAsset({
    assetSlug,
    categorySlug: 'fixed-income',
    grouping,
    portfolio: selectedPortfolio?.id,
  });

  return {
    accruedInterest: asset?.accruedInterest ?? 0,
    asset,
    backUrl,
    couponsReceived: asset?.couponsReceived ?? 0,
    pnLExclCpn: asset?.pnLExclCpn ?? 0,
    aIatPurchase: asset?.aIatPurchase ?? 0,
    dividendsReceived: asset?.dividendsReceived ?? 0,
    duration: asset?.duration ?? 0,
    marketPrice: asset?.marketPrice ?? 0,
    marketValue: asset?.marketValue ?? 0,
    cost: asset?.cost ?? 0,
    maturityDate: asset?.maturityDate ?? 0,
    nominalAmount: asset?.shares ?? 0,
    parentPath,
    shares: asset?.shares ?? 0,
    title: asset?.fullName ?? '',
    totalProfitLoss: asset?.totalProfitAndLoss ?? 0,
    url,
    yieldToWorst: asset?.ytw ?? 0,
    tey: asset?.tey ?? 0,
    ttc: asset?.ttc ?? 0,
    rating: asset?.rating ?? '',
  };
}
