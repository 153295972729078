import styled from 'styled-components';

import from from 'styles/responsive';

export const Container = styled.div`
  ${from.laptop`
    grid-column: span 2;
  `};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.375rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 1.4;
  margin: 0 0 1.5rem 0;

  ${from.tablet`
    margin: 0 0 0rem 0;
  `}
`;

export const ChartContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.dark1};
  padding: 24px 39px;
  border-radius: 16px;
`;

export const ChartInfo = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 0.2fr 1fr;
  background-color: ${({ theme }) => theme.colors.dark1};
  margin-bottom: 16px;
  gap: 30px;
`;

export const ChartContainerHeader = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 0.2fr 1fr;
  padding: 0px 39px 16px 39px;
  gap: 30px;
`;

export const Column = styled.div<{ $rows: number; $isNameColumn?: boolean }>`
  display: grid;
  grid-tempalte-rows: repeat(${({ $rows }) => $rows}, 1fr);
  padding-bottom: 25px;
  padding-top: 5px;
  align-items: center;
  justify-content: ${({ $isNameColumn }) => ($isNameColumn ? 'start' : 'end')};
  text-align: ${({ $isNameColumn }) => ($isNameColumn ? 'left' : 'right')};
  & :not(:first-child) {
    opacity: ${({ $isNameColumn }) => ($isNameColumn ? 0.5 : 0.75)};
  }

  & :first-child {
    font-weight: ${({ theme }) => theme.weights.medium};
  }
`;

export const TextSmall = styled.span`
  font-size: 14px;
`;

export const TextSmallBold = styled.span<{
  $align?: string;
  $paddingLeft?: number;
}>`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weights.medium};
  text-align: ${({ $align }) => $align ?? 'right'};
  padding-left: ${({ $paddingLeft }) => $paddingLeft ?? 0}px;
`;
